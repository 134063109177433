.misReclamos-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
    .misReclamos-container-main {
        display: flex;
        flex-direction: column;
        width: 94%;
    }
        .misReclamos-container-main-title {
            color: var(--gray700);
        }
        .misReclamos-container-main-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
        }
            .misReclamos-container-main-list > * {
                
            }
        .misReclamos-container-main-emptyList {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
            .misReclamos-container-main-emptyList-text {
                color: var(--gray400);
            }