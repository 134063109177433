.countrySelector-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    background-color: var(--primary500);
}
    .countrySelector-container-countriesContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
    }
        .countrySelector-container-countriesContainer-item {
            display: flex;
            flex-direction: row;
            min-width: 194px;
            background: var(--primary400);
            border-radius: 25px;
            margin: 5px 0;
            padding-left: 5px;
            padding-top: 5px;
            cursor: pointer;
            text-decoration: none;
        }
            .countrySelector-container-countriesContainer-item:active {
                background: #513DAC;
            }
            .countrySelector-container-countriesContainer-item-countryWrapper {
                margin-top: 5px;
            }
            .countrySelector-container-countriesContainer-item-text {
                font-style: normal;
                font-weight: normal;
                font-size: 1.2rem;
                line-height: 22px;
                color: var(--white);
            }