.checkOutProfile-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 50px;
}
    @media only screen and (max-width: 37.5em) {
        .checkOutProfile-container {
            width: 95%;
            flex-direction: column;
        }}
    .checkOutProfile-container-endWrapper {
        display: flex;
        flex-direction: column;
        width: 30%;
        margin-right: 20px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutProfile-container-endWrapper {
                width: 100%;
                position: fixed;
                bottom: 0;
                background-color: var(--white);
                z-index: 20;
                align-items: center;
                justify-content: center;
                box-shadow: 0px -4px 30px 0px rgba(0, 0, 0, 0.10);
            }}
        .checkOutProfile-container-endWrapper-main {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
            @media only screen and (max-width: 37.5em) {
                .checkOutProfile-container-endWrapper-main {
                    width: 90%;
                    margin-bottom: 10px;
                }}
    .checkOutProfile-container-endContainerResponsive {
        display: none;
        flex-direction: column;
        margin-top: 30px;
        width: 100%;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutProfile-container-endContainerResponsive {
                display: flex;
            }}
        .checkOutProfile-container-endContainerResponsive-buttonWrapper {
            width: 100%;
            margin-bottom: 70px;
        }
        .checkOutProfile-container-endContainerResponsive-fixed {
            position: fixed;
            bottom: 0;
            width: 90%;
            background-color: var(--scondaryBackgroundColor);
        }
            .checkOutProfile-container-endContainerResponsive-fixed-code {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
            }
                .checkOutProfile-container-endContainerResponsive-fixed-code-input {
                    border: none;
                    background-color: transparent;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 0.75rem;
                    line-height: 24px;
                    color: var(--black);
                    width: 100%;
                    margin-left: 20px;
                }
                .checkOutProfile-container-endContainerResponsive-fixed-code-input:focus {
                    outline: none;
                }
                .checkOutProfile-container-endContainerResponsive-fixed-codeLine {
                    height: 1px;
                    width: 100%;
                    background-color: #C4C4C4;
                    opacity: 0.5;
                }
                .checkOutProfile-container-endContainerResponsive-fixed-price {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin: 15px 0;
                }
                    .checkOutProfile-container-endContainerResponsive-fixed-price-text {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 0.9375rem;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        color: var(--primary500);
                        margin: 0;
                    }
                    .checkOutProfile-container-endContainerResponsive-fixed-price-price {
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.875rem;
                        line-height: 32px;
                        text-align: right;
                        color: var(--primary500);
                        margin: 0;             
                    }
    .checkOutProfile-container-detailsWrapper {
        width: 27%;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutProfile-container-detailsWrapper {
            }}
    .checkOutProfile-container-main {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin-left: 50px;
    }
        @media only screen and (max-width: 37.5em) {
            .checkOutProfile-container-main {
                width: 91%;
                margin-left: 25px;
                padding-bottom: 300px;
            }}
        .checkOutProfile-container-main-titulo {
            color: var(--gray700);
            margin-bottom: 4px;
        }
        .checkOutProfile-container-main-volver {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: var(--primary500);
            margin-bottom: 24px;
            cursor: pointer;
        }
        .checkOutProfile-container-main-reLogText {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-right: auto;
            margin-bottom: 24px;
        }
            .checkOutProfile-container-main-reLogText p {
                color: var(--primary500);
                cursor: pointer;
                font-weight: 700;
            }

        .checkOutProfile-container-main-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 97.5%;
        }
            @media only screen and (max-width: 37.5em) {
                .checkOutProfile-container-main-list {
                    flex-direction: column;
                    align-items: center;
                    flex-wrap: nowrap;
                    width: 100%;
                }}
            .checkOutProfile-container-main-list-list {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 31%;
            }
                .checkOutProfile-container-main-list-list > * {
                    width: 31%;
                }
            .checkOutProfile-container-main-list-invis {
                width: 31%;
                margin-bottom: 30px;
            }
            .checkOutProfile-container-main-list > * {
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutProfile-container-main-list > * {
                        width: 100%;
                    }}
            .checkOutProfile-container-main-list-item-specialInput {
                display: flex;   
                flex-direction: column;
                justify-content: flex-start;
                width: 30%;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutProfile-container-main-list-item-specialInput {
                        width: 100%;
                    }}
                .checkOutProfile-container-main-list-item-specialInput-label {
                    display: flex;
                    flex-direction: row;
                }
                    .checkOutProfile-container-main-list-item-specialInput-label-text {
                        color: var(--tertiary500);
                    }
                    .checkOutProfile-container-main-list-item-specialInput-label-requiered {
                        color: var(--statusErrorPrimary);
                    }
                .checkOutProfile-container-main-list-item-specialInput-main {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                    .checkOutProfile-container-main-list-item-specialInput-main-input {
                        border: none;
                        background-color: transparent;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 1.25rem;
                        line-height: 24px;
                        color: var(--black);
                        width: 100%;
                        font-family: Lato, sans-serif;
                        padding: 10px;
                        border-bottom: 1px solid var(--gray300);
                    }
                    .checkOutProfile-container-main-list-item-specialInput-main-input:focus {
                        outline: none;
                    }
                    .checkOutProfile-container-main-list-item-specialInput-main-input::placeholder {
                        font-style: normal;
                        font-weight: 300;
                        font-size: 1rem;
                        line-height: 1.5rem;                
                        color: var(--gray300);
                        font-family: Lato, sans-serif;
                    }
                    .checkOutProfile-container-main-list-item-specialInput-main-line {
                        color: var(--gray300);
                        margin: 0 10px;
                    }
                    .checkOutProfile-container-main-list-item-specialInput-main-status {
                        margin-left: -20px;
                    }
            .checkOutProfile-container-main-list-item-selectorWrapper {
                display: flex;
                flex-direction: column;
                width: 31%;
                margin-bottom: 30px;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutProfile-container-main-list-item-selectorWrapper {
                        width: 100%;
                    }}
                .checkOutProfile-container-main-list-item-selectorWrapper-title {
                    color: var(--customTextInputLabel);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin: 0;
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                }
                .checkOutProfile-container-main-list-item-selectorWrapper-title ul {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.25rem;
                    line-height: 24px;
                    color: red;
                    margin: 0;
                    padding: 0;
                }
            .checkOutProfile-container-main-list-innerList {
                display: flex;
                flex-direction: row;
                width: 103%;
            }
                @media only screen and (max-width: 37.5em) {
                    .checkOutProfile-container-main-list-innerList {
                        width: 105%;
                    }}