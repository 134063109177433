.FormFieldNumberStyleTwo-container-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 20px auto;
}
    .FormFieldNumberStyleTwo-container-main-img {
        width: 10%;
        margin: auto;
        margin-top: 20px;
    }
    .FormFieldNumberStyleTwo-container-main-title {
        margin: 20px 0;
        color: var(--gray700);
    }