.numCotComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    border: 1px solid var(--gray200);
    background: var(--white);
    width: 318px;
    padding: 11px 16px;
    margin-right: auto;
    margin-bottom: 19px;
    margin-top: 20px;
}
    .numCotComponent-text {
        color: var(--gray700);
    }